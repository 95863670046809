import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/github/actions-runner/_work/ssg-widgets/ssg-widgets/apps/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "change-log"
    }}>{`Change Log`}</h1>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2 {...{
      "id": "0217-2021-11-26"
    }}>{`0.2.17 (2021-11-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "0216-2021-11-23"
    }}>{`0.2.16 (2021-11-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "0215-2021-11-19"
    }}>{`0.2.15 (2021-11-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "0214-2021-11-18"
    }}><a parentName="h2" {...{
        "href": "https://github.com/tdsoftpl/ssg-widgets/compare/@ssg-b2b/docs@0.2.13...@ssg-b2b/docs@0.2.14"
      }}>{`0.2.14`}</a>{` (2021-11-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "0213-2021-11-05"
    }}>{`0.2.13 (2021-11-05)`}</h2>
    <h3 {...{
      "id": "bug-fixes"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`progress-bars:`}</strong>{` useTheme import from wrong package (`}<a parentName="li" {...{
          "href": "https://github.com/tdsoftpl/ssg-widgets/commit/c4ff36149a4efdf008dfbea8f538064868669a84"
        }}>{`c4ff361`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "0212-2021-11-04"
    }}>{`0.2.12 (2021-11-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "0211-2021-11-02"
    }}>{`0.2.11 (2021-11-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "0210-2021-11-02"
    }}>{`0.2.10 (2021-11-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "029-2021-10-27"
    }}>{`0.2.9 (2021-10-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "028-2021-10-26"
    }}>{`0.2.8 (2021-10-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "027-2021-10-25"
    }}>{`0.2.7 (2021-10-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "026-2021-10-22"
    }}>{`0.2.6 (2021-10-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "025-2021-10-21"
    }}>{`0.2.5 (2021-10-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "024-2021-10-08"
    }}>{`0.2.4 (2021-10-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "023-2021-10-08"
    }}>{`0.2.3 (2021-10-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "022-2021-10-07"
    }}><a parentName="h2" {...{
        "href": "https://github.com/tdsoftpl/ssg-widgets/compare/@ssg-b2b/docs@0.2.1...@ssg-b2b/docs@0.2.2"
      }}>{`0.2.2`}</a>{` (2021-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "021-2021-09-29"
    }}><a parentName="h2" {...{
        "href": "https://github.com/tdsoftpl/ssg-widgets/compare/@ssg-b2b/docs@0.2.0...@ssg-b2b/docs@0.2.1"
      }}>{`0.2.1`}</a>{` (2021-09-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h1 {...{
      "id": "020-2021-09-28"
    }}><a parentName="h1" {...{
        "href": "https://github.com/tdsoftpl/ssg-widgets/compare/@ssg-b2b/docs@0.2.0-alpha.1...@ssg-b2b/docs@0.2.0"
      }}>{`0.2.0`}</a>{` (2021-09-28)`}</h1>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h1 {...{
      "id": "020-alpha1-2021-09-22"
    }}><a parentName="h1" {...{
        "href": "https://github.com/tdsoftpl/ssg-widgets/compare/@ssg-b2b/docs@0.2.0-alpha.0...@ssg-b2b/docs@0.2.0-alpha.1"
      }}>{`0.2.0-alpha.1`}</a>{` (2021-09-22)`}</h1>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h1 {...{
      "id": "020-alpha0-2021-09-09"
    }}><a parentName="h1" {...{
        "href": "https://github.com/tdsoftpl/ssg-widgets/compare/@ssg-b2b/docs@0.1.2-alpha.1...@ssg-b2b/docs@0.2.0-alpha.0"
      }}>{`0.2.0-alpha.0`}</a>{` (2021-09-09)`}</h1>
    <h3 {...{
      "id": "features"
    }}>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`widget-hub:`}</strong>{` add buffer, add bet button theme values, update docs package dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/tdsoftpl/ssg-widgets/commit/71927c9e56669c533fc8483519e4e76f6c4da2e1"
        }}>{`71927c9`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "012-alpha1-2021-08-23"
    }}><a parentName="h2" {...{
        "href": "https://github.com/tdsoftpl/ssg-widgets/compare/@ssg-b2b/docs@0.1.2-alpha.0...@ssg-b2b/docs@0.1.2-alpha.1"
      }}>{`0.1.2-alpha.1`}</a>{` (2021-08-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @ssg-b2b/docs`}</p>
    <h2 {...{
      "id": "012-alpha0-2021-08-18"
    }}>{`0.1.2-alpha.0 (2021-08-18)`}</h2>
    <h3 {...{
      "id": "bug-fixes-1"
    }}>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` dependencies hoisting issues (`}<a parentName="li" {...{
          "href": "https://github.com/tdsoftpl/ssg-widgets/commit/97994c38beb4ebe5d23c7fafcba0f4bd91bf17bb"
        }}>{`97994c3`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`docs:`}</strong>{` temporarily set base option to "/docusaurus/" (`}<a parentName="li" {...{
          "href": "https://github.com/tdsoftpl/ssg-widgets/commit/5cc5d7ddbccb92f24bb38dd324b78855a50d4135"
        }}>{`5cc5d7d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`gh-actions/docs:`}</strong>{` change base url (`}<a parentName="li" {...{
          "href": "https://github.com/tdsoftpl/ssg-widgets/commit/3df9de777693c868ae5a36f4b090bc8203fde80b"
        }}>{`3df9de7`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`gh-actions/docs:`}</strong>{` change base url to "/" (`}<a parentName="li" {...{
          "href": "https://github.com/tdsoftpl/ssg-widgets/commit/a1db660275ddaa3bdb74d886bc44479dfe600c9b"
        }}>{`a1db660`}</a>{`)`}</li>
      <li parentName="ul">{`prettier config (`}<a parentName="li" {...{
          "href": "https://github.com/tdsoftpl/ssg-widgets/commit/dab843d2daf791c09e6c93e40ced18e0d8d6f45c"
        }}>{`dab843d`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      